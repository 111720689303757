import { gql } from "@apollo/client";
import { Divider } from "@mui/material";
import { readminSidebarContentContext } from "helpers/contexts";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import React, { useLayoutEffect, useMemo, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

import ReadminSidebarItem from "./ReadminSidebarItem";

export default function ReadminSidebarContent() {
  const [highlightItem, highlightItemSet] = useState(null);
  const [allItems, allItemsSet] = useState([]);

  const [data] = useData(gql`
    query ReadminSidebar {
      currentUser {
        id
        email
        orgAdmins {
          id
          org {
            id
          }
        }
        venueAdmins {
          id
          venue {
            id
            name
          }
        }
        apiAdmins {
          id
          apiPartner {
            id
          }
        }
      }
      readminApiPartners: readminObjects(typename: "ApiPartner") {
        id
        readminName
      }
      onsenUrl
    }
  `);

  const location = useLocation();
  const currentPathname = location.pathname.toLowerCase();

  const orgId = matchPath({ path: "/readmin/orgs/:orgId/*" }, location.pathname)?.params.orgId;

  // auto highlight associated item from URL
  const autoHighlightItemSubpath = useMemo(
    () =>
      // if pathname exact match
      allItems.find((i) => i.href?.toLowerCase() === currentPathname) ||
      // ... or pathname is a subpath of the item
      allItems.find(
        (i) => i.href && currentPathname.startsWith(i.href?.toLowerCase()) && !i.disableAutoHighlightSubpath,
      ),
    [currentPathname, allItems],
  );
  useLayoutEffect(() => {
    if (autoHighlightItemSubpath) {
      highlightItemSet(autoHighlightItemSubpath);
    }
  }, [autoHighlightItemSubpath?.id]);

  const isAdmin = useIsAdmin();

  return (
    <readminSidebarContentContext.Provider
      value={{
        highlightItem,
        highlightItemSet,
        allItemsSet,
      }}
    >
      <ReadminSidebarItem href="/readmin" label="Dashboard" disableAutoHighlightSubpath />
      {data?.currentUser?.orgAdmins.map((orgAdmin) => (
        <OrgSidebarItem key={orgAdmin.id} orgId={orgAdmin.org.id} />
      ))}
      {!!orgId && !data?.currentUser?.orgAdmins.some((orgAdmin) => orgAdmin.org.id === orgId) && (
        <OrgSidebarItem orgId={orgId} />
      )}
      {data?.currentUser?.venueAdmins.map((venueAdmin) => (
        <ReadminSidebarItem
          key={venueAdmin.id}
          label={venueAdmin.venue.name}
          href={`/readmin/venues/${venueAdmin.venue.id}`}
        />
      ))}
      {data?.currentUser?.apiAdmins.map((apiAdmin) => (
        <ApiPartnerSidebarItem key={apiAdmin.id} apiPartnerId={apiAdmin.apiPartner.id} />
      ))}
      {isAdmin && (
        <ReadminSidebarItem label="Glory League Admin">
          <ReadminSidebarItem label="Operation Warnings" href="/readmin/ops_warnings" />
          <ReadminSidebarItem label="Integration">
            <ReadminSidebarItem label="API Partners" href="/readmin/api_partners" disableAutoHighlightSubpath />
            <Divider />
            {data?.readminApiPartners?.map((apiPartner) => (
              <ApiPartnerSidebarItem key={apiPartner.id} apiPartnerId={apiPartner.id} />
            ))}
            <Divider />
            <ReadminSidebarItem label="GameDay Associations" href="/readmin/gd_associations" />
            <ReadminSidebarItem label="GameDay Venues" href="/readmin/gd_venues" />
            <ReadminSidebarItem label="PlayHQ Organisations" href="/readmin/play_hq_organisations" />
          </ReadminSidebarItem>
          <ReadminSidebarItem label="Organisation Management">
            <ReadminSidebarItem label="Ad Assets" href="/readmin/ad_assets" />
            <ReadminSidebarItem label="Ad Presets" href="/readmin/ad_presets" />
            <ReadminSidebarItem label="Organisations" href="/readmin/orgs" disableAutoHighlightSubpath />
            <ReadminSidebarItem label="Organisation Admins" href="/readmin/org_admins" />
            <ReadminSidebarItem label="Courts" href="/readmin/courts" />
            <ReadminSidebarItem label="Deliveries" href="/readmin/deliveries" />
            <ReadminSidebarItem label="Event Videos" href="/readmin/event_videos" />
            <ReadminSidebarItem label="Games" href="/readmin/games" />
            <ReadminSidebarItem label="Game Submissions" href="/readmin/game_submissions" />
            <ReadminSidebarItem label="Highlights" href="/readmin/events" />
            <ReadminSidebarItem label="Incidents" href="/readmin/incidents" />
            <ReadminSidebarItem label="Leagues" href="/readmin/leagues" />
            <ReadminSidebarItem label="Live Streams" href="/readmin/live_streams" />
            <ReadminSidebarItem label="Onsen Popup Promos" href="/readmin/onsen_popup_promos" />
            <ReadminSidebarItem label="People" href="/readmin/people" />
            <ReadminSidebarItem label="POTW Competitions" href="/readmin/potw_competitions" />
            <ReadminSidebarItem label="POTW Entries" href="/readmin/potw_entries" />
            <ReadminSidebarItem label="POTW Ratings" href="/readmin/potw_ratings" />
            <ReadminSidebarItem label="POTW Videos" href="/readmin/potw_videos" />
            <ReadminSidebarItem label="Public League Pages" href="/readmin/public_league_pages" />
            <ReadminSidebarItem label="Scheduled Games" href="/readmin/recurring_recording_occurrences" />
            <ReadminSidebarItem label="Scorekeeper Users" href="/readmin/scorekeeper_users" />
            <ReadminSidebarItem label="Teams" href="/readmin/teams" />
            <ReadminSidebarItem label="Team Registration Form" href="/readmin/team_registrations" />
            <ReadminSidebarItem label="Videos" href="/readmin/videos" />
          </ReadminSidebarItem>
          <ReadminSidebarItem label="Venue Management">
            <ReadminSidebarItem label="Cameras" href="/readmin/cameras" />
            <ReadminSidebarItem label="Device Port Mappings" href="/readmin/device_port_mappings" />
            <ReadminSidebarItem label="Network Devices" href="/readmin/network_devices" />
            <ReadminSidebarItem label="Recording Blocks" href="/readmin/recording_blocks" />
            <ReadminSidebarItem label="Surfaces" href="/readmin/surfaces" />
            <ReadminSidebarItem label="Venues" href="/readmin/venues" />
            <ReadminSidebarItem label="Venue Admins" href="/readmin/venue_admins" />
            <ReadminSidebarItem label="Venue Servers" href="/readmin/venue_servers" />
            <ReadminSidebarItem label="Venue Server Chef Runs" href="/readmin/venue_server_chef_runs" />
            <ReadminSidebarItem label="Venue Server Commands" href="/readmin/venue_server_commands" />
            <ReadminSidebarItem label="Video Cut Attempts" href="/readmin/video_cut_attempts" />
          </ReadminSidebarItem>
          <ReadminSidebarItem label="Others">
            <ReadminSidebarItem label="Audit Events" href="/readmin/paper_trail_versions" />
            <ReadminSidebarItem label="Beta Features" href="/readmin/beta_features" />
            <ReadminSidebarItem label="Camera Models" href="/readmin/camera_models" />
            <ReadminSidebarItem label="Cities" href="/readmin/cities" />
            <ReadminSidebarItem label="Motioncrop Tests" href="/readmin/motioncrop_tests" />
            <ReadminSidebarItem label="Motioncrop Test Reports" href="/readmin/motioncrop_test_reports" />
            <ReadminSidebarItem label="Original Video Request" href="/readmin/original_video_requests" />
            <ReadminSidebarItem label="Scorekeeper Devices" href="/readmin/scorekeeper_devices" />
            <ReadminSidebarItem label="SendGrid Events" href="/readmin/sendgrid_events" />
            <ReadminSidebarItem label="System Notes" href="/readmin/notes" />
            <ReadminSidebarItem label="Users" href="/readmin/users" />
            <ReadminSidebarItem label="Video Objects" href="/readmin/video_objects" />
            <Divider />
            <ReadminSidebarItem label="Active Admin" href="/active_admin" data-react-router-disabled />
            <ReadminSidebarItem label="Sidekiq" href="/sidekiq" data-react-router-disabled />
            <ReadminSidebarItem label="PgHero" href="/pghero" data-react-router-disabled />
          </ReadminSidebarItem>
        </ReadminSidebarItem>
      )}
      <ReadminSidebarItem href="https://www.gloryleague.basketball/admin/welcome" label="Help" />
    </readminSidebarContentContext.Provider>
  );
}

function OrgSidebarItem({ orgId }) {
  const [data] = useData(
    gql`
      query OrgSidebarItem($orgId: ID!) {
        org(id: $orgId) {
          id
          name
          usePublicLeaguePages
          venues {
            id
            name
          }
        }
      }
    `,
    { orgId },
  );

  return (
    <ReadminSidebarItem label={data?.org.name}>
      <ReadminSidebarItem label="Dashboard" href={`/readmin/orgs/${orgId}`} disableAutoHighlightSubpath />
      <ReadminSidebarItem label="Competitions">
        <ReadminSidebarItem label="Leagues" href={`/readmin/orgs/${orgId}/leagues`} />
        <ReadminSidebarItem label="Teams" href={`/readmin/orgs/${orgId}/teams`} />
        <ReadminSidebarItem label="Team Registration Forms" href={`/readmin/orgs/${orgId}/team_registrations`} />
        <ReadminSidebarItem label="People" href={`/readmin/orgs/${orgId}/people`} />
      </ReadminSidebarItem>
      <ReadminSidebarItem label="Video">
        <ReadminSidebarItem label="Games" href={`/readmin/orgs/${orgId}/games`} />
        <ReadminSidebarItem label="Scheduled Games" href={`/readmin/orgs/${orgId}/recurring_recording_occurrences`} />
        <ReadminSidebarItem label="Live Streams" href={`/readmin/orgs/${orgId}/live_streams`} />
        <ReadminSidebarItem label="Highlights" href={`/readmin/orgs/${orgId}/events`} />
        <ReadminSidebarItem label="Incidents" href={`/readmin/orgs/${orgId}/incidents`} />
        <ReadminSidebarItem label="Referee Access" href={`/readmin/orgs/${orgId}/games/manage_referees`} />
        <ReadminSidebarItem label="Game Submissions" href={`/readmin/orgs/${orgId}/game_submissions`} />
        <ReadminSidebarItem label="Videos" href={`/readmin/orgs/${orgId}/videos`} />
        <ReadminSidebarItem label="Deliveries" href={`/readmin/orgs/${orgId}/deliveries`} />
      </ReadminSidebarItem>
      <ReadminSidebarItem label="Plays of the Week">
        <ReadminSidebarItem label="POTW Competitions" href={`/readmin/orgs/${orgId}/potw_competitions`} />
        <ReadminSidebarItem label="POTW Entries" href={`/readmin/orgs/${orgId}/potw_entries`} />
        <ReadminSidebarItem label="POTW Ratings" href={`/readmin/orgs/${orgId}/potw_ratings`} />
      </ReadminSidebarItem>
      <ReadminSidebarItem label="Settings">
        <ReadminSidebarItem label="Ad Assets" href={`/readmin/orgs/${orgId}/ad_assets`} />
        <ReadminSidebarItem label="Ad Presets" href={`/readmin/orgs/${orgId}/ad_presets`} />
        {data?.org.usePublicLeaguePages && (
          <ReadminSidebarItem label="Public Leagues" href={`/readmin/orgs/${orgId}/public_league_pages`} />
        )}
        <ReadminSidebarItem label="Organisation Admins" href={`/readmin/orgs/${orgId}/org_admins`} />
        <ReadminSidebarItem label="Scorekeeper Users" href={`/readmin/orgs/${orgId}/scorekeeper_users`} />
        <ReadminSidebarItem label="System Notes" href={`/readmin/orgs/${orgId}/notes`} />
      </ReadminSidebarItem>
      <ReadminSidebarItem label="Venues">
        {data?.org.venues.map((venue) => (
          <ReadminSidebarItem key={venue.id} label={venue.name} href={`/readmin/orgs/${orgId}/venues/${venue.id}`} />
        ))}
        <Divider />
        <ReadminSidebarItem label="Courts" href={`/readmin/orgs/${orgId}/courts`} />
        <ReadminSidebarItem label="Recordings" href={`/readmin/orgs/${orgId}/recordings`} />
      </ReadminSidebarItem>
      <ReadminSidebarItem label="Reports">
        <ReadminSidebarItem label="Usage Report" href={`/readmin/orgs/${orgId}/usage_report`} />
      </ReadminSidebarItem>
    </ReadminSidebarItem>
  );
}

function ApiPartnerSidebarItem({ apiPartnerId }) {
  const [data] = useData(
    gql`
      query ApiPartnerSidebarItem($apiPartnerId: ID!) {
        apiPartner(id: $apiPartnerId) {
          id
          name
        }
      }
    `,
    { apiPartnerId },
  );

  return (
    <ReadminSidebarItem label={data?.apiPartner.name}>
      <ReadminSidebarItem
        label="Dashboard"
        href={`/readmin/api_partners/${apiPartnerId}`}
        disableAutoHighlightSubpath
      />
      <ReadminSidebarItem label="Organisations" href={`/readmin/api_partners/${apiPartnerId}/api_organisations`} />
      <ReadminSidebarItem label="Courts" href={`/readmin/api_partners/${apiPartnerId}/api_courts`} />
      <ReadminSidebarItem label="Competitions" href={`/readmin/api_partners/${apiPartnerId}/api_competitions`} />
      <ReadminSidebarItem label="Game Schedules" href={`/readmin/api_partners/${apiPartnerId}/api_game_schedules`} />
      <ReadminSidebarItem label="Teams" href={`/readmin/api_partners/${apiPartnerId}/api_teams`} />
      <ReadminSidebarItem label="Profiles" href={`/readmin/api_partners/${apiPartnerId}/api_profiles`} />
      <ReadminSidebarItem label="GraphiQL" href={`/readmin/api_partners/${apiPartnerId}/graphiql`} />
      <ReadminSidebarItem
        label="GraphQL Requests"
        href={`/readmin/api_partners/${apiPartnerId}/api_graphql_requests`}
      />
    </ReadminSidebarItem>
  );
}
