import { gql } from "@apollo/client";
import DataTable from "controls/DataTable";
import FiltersBar from "filters/FiltersBar";
import SearchTermFilter from "filters/SearchTermFilter";
import useData from "hooks/useData";
import useSearchTerm from "hooks/useSearchTerm";
import React from "react";
import ReadminObjectThumbnail from "readmin_pages/shared/ReadminObjectThumbnail";

export default function ReadminDashboardSearch() {
  const [searchTerm, searchTermSet, searchTermDebounced] = useSearchTerm();
  const readminFilterBar = searchTermDebounced ? { searchTerm: searchTermDebounced } : null;
  const [data] = useData(
    gql`
      query FullAdminDashboard($readminFilterBar: ReadminFilterBar) {
        readminOrgs: readminObjects(filterBar: $readminFilterBar, typename: "Org") {
          id
          ...ReadminDashboardSearch_TableFragment
        }
        readminVenues: readminObjects(filterBar: $readminFilterBar, typename: "Venue") {
          id
          ...ReadminDashboardSearch_TableFragment
        }
        readminVenueServers: readminObjects(filterBar: $readminFilterBar, typename: "VenueServer") {
          id
          ...ReadminDashboardSearch_TableFragment
        }
        readminCameras: readminObjects(filterBar: $readminFilterBar, typename: "Camera") {
          id
          ...ReadminDashboardSearch_TableFragment
        }
      }
      fragment ReadminDashboardSearch_TableFragment on ReadminObject {
        id
        readminName
        readminLogoUrl
        readminUrl
      }
    `,
    { readminFilterBar },
  );

  return (
    <>
      <FiltersBar>
        <SearchTermFilter
          value={searchTerm}
          onChange={searchTermSet}
          helperText={
            <>
              Search <b>Organisations</b>, <b>Venues</b>, <b>Venue Servers</b>, and <b>Cameras</b> by keywords.
            </>
          }
        />
      </FiltersBar>
      {readminFilterBar && (
        <DataTable
          defaultMode="grid"
          columns={["Name", "Type"]}
          rows={
            data &&
            [
              //
              ...data.readminOrgs,
              ...data.readminVenues,
              ...data.readminVenueServers,
              ...data.readminCameras,
            ].map((record) => ({
              key: `${record.id}:${record.__typename}`,
              cells: [record.readminName, record.__typename],
              thumbnail: <ReadminObjectThumbnail id={record.id} typename={record.__typename} />,
              defaultAction: {
                href: record.readminUrl,
                content: "View",
              },
            }))
          }
        />
      )}
    </>
  );
}
