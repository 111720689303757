import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import ScorekeeperUserForm from "./Form";

AddScorekeeperUserDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add Scorekeeper User",
  };
};

export default function AddScorekeeperUserDialog({ orgId, onClose, onSubmit }) {
  const addScorekeeperUser = useActionFragment("addScorekeeperUser");

  return (
    <FormDialog
      header="Add Scorekeeper User"
      onClose={onClose}
      onSubmit={async (formData) => {
        await addScorekeeperUser({ input: formData });
        await onSubmit?.();
      }}
    >
      <ScorekeeperUserForm
        scorekeeperUsers={[
          {
            org: orgId && {
              id: orgId,
              __typename: "Org",
            },
          },
        ]}
      />
    </FormDialog>
  );
}
