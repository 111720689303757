import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import PeopleForm from "./Form";

AddPersonDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add Person",
  };
};

export default function AddPersonDialog({ orgId, onSubmit, personFragment = "id", onClose }) {
  const addPerson = useActionFragment("addPerson", `person { ${personFragment} }`);

  return (
    <FormDialog
      loading={!orgId}
      header="Add person profile"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addPerson({
          input: formData,
        });
        await onSubmit?.(result.addPerson.person);
      }}
    >
      <PeopleForm
        people={[
          {
            org: orgId && { id: orgId, __typename: "Org" },
          },
        ]}
      />
    </FormDialog>
  );
}
