import React from "react";

export default function FiltersBar({ children, ...others }) {
  return (
    <div
      {...others}
      style={{
        display: "flex",
        flexFlow: "row wrap",
        gap: 10,
      }}
    >
      {children}
    </div>
  );
}
