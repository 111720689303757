import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Plus } from "mdi-material-ui";
import React from "react";

import GameForm from "../games/GameForm";

RecurringRecordingOccurrenceAddMissingGameDialog.useButtonProps = ({ recurringRecordingOccurrenceId }) => {
  const [data] = useData(
    gql`
      query RecurringRecordingOccurrenceAddMissingGameDialog($recurringRecordingOccurrenceId: ID!) {
        recurringRecordingOccurrence(id: $recurringRecordingOccurrenceId) {
          id
          missingGames
        }
      }
    `,
    { recurringRecordingOccurrenceId },
  );

  return {
    hidden: !data?.recurringRecordingOccurrence.missingGames,
    icon: <Plus />,
    content: "Add missing game",
  };
};

export default function RecurringRecordingOccurrenceAddMissingGameDialog({
  recurringRecordingOccurrenceId,
  onSubmit = noop,
  ...others
}) {
  const [data, dataMeta] = useData(
    gql`
      query RecurringRecordingOccurrenceAddMissingGameDialog($recurringRecordingOccurrenceId: ID!) {
        recurringRecordingOccurrence(id: $recurringRecordingOccurrenceId) {
          id
          startTime
          endTime
          org {
            id
          }
          league {
            id
          }
          court {
            id
          }
          homeTeam {
            id
          }
          awayTeam {
            id
          }
        }
      }
    `,
    { recurringRecordingOccurrenceId },
  );
  const orgAddGame = useActionFragment("orgAddGame");
  const orgId = data?.recurringRecordingOccurrence.org.id;

  return (
    <FormDialog
      header="Add missing game"
      loading={dataMeta.loading}
      // loading
      onSubmit={async (formData) => {
        await orgAddGame({
          input: {
            orgId,
            ...formData,
          },
        });
        await onSubmit();
      }}
      {...others}
    >
      <GameForm
        game={{
          league: data?.recurringRecordingOccurrence?.league,
          court: data?.recurringRecordingOccurrence?.court,
          home: data?.recurringRecordingOccurrence?.homeTeam,
          away: data?.recurringRecordingOccurrence?.awayTeam,
          playedAt: data?.recurringRecordingOccurrence?.startTime,
          completedAt: data?.recurringRecordingOccurrence?.endTime,
        }}
        {...others}
      />
    </FormDialog>
  );
}
