import { gql } from "@apollo/client";
import FormSection from "controls/FormSection";
import MultiValuesField from "controls/MultiValuesField";
import toCapitalizedWords from "helpers/toCapitalizedWords";
import useData from "hooks/useData";
import NumberInput from "inputs/NumberInput";
import SelectInput from "inputs/SelectInput";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const LeaguesFormFragment = gql`
  fragment LeaguesFormFragment on League {
    id
    org {
      id
    }
    sport
    name
    age
    gender
    active
    potwEnabled
    videoRecordingPermitted
    sharingDisabled
    anonymisePeople
    pointsForWin
    pointsForDraw
    pointsForLoss
    pointsForForfeit
    scoreboardFoulsVisible
    adPreset {
      id
    }
  }
`;

export default function LeaguesForm({ leagues }) {
  const [data] = useData(gql`
    query LeaguesForm {
      sports
    }
  `);

  leagues = leagues.map((league) => ({
    active: true,
    videoRecordingPermitted: true,
    pointsForWin: 2,
    pointsForDraw: 1,
    pointsForLoss: 0,
    pointsForForfeit: 1,
    ...league,
  }));

  return (
    <>
      {leagues?.every((league) => !league.id) && (
        <MultiValuesField
          label="Organisation"
          name="org"
          required
          defaultValues={leagues?.map((league) => league.org)}
          input={<ReadminObjectInput optionsTypename="Org" />}
        />
      )}
      <MultiValuesField
        label="Sport"
        name="sport"
        required
        defaultValues={leagues?.map((league) => league.sport)}
        input={<SelectInput options={data?.sports.map((sport) => [toCapitalizedWords(sport), sport])} />}
      />
      <MultiValuesField
        label="Name"
        name="name"
        required
        defaultValues={leagues?.map((league) => league.name)}
        input={<TextInput />}
      />
      <MultiValuesField
        label="Age"
        name="age"
        defaultValues={leagues?.map((league) => league.age)}
        input={<SelectInput options={["adult", "child", "teen"]} />}
      />
      <MultiValuesField
        label="Gender"
        name="gender"
        defaultValues={leagues?.map((league) => league.gender)}
        input={<SelectInput options={["female", "male", "mixed"]} />}
      />
      <MultiValuesField
        label="Active"
        name="active"
        defaultValues={leagues?.map((league) => league.active)}
        input={<SwitchInput />}
        helperText={<>League will be available on Scorekeeper.</>}
      />
      <MultiValuesField
        label="Ad Preset"
        name="adPreset"
        defaultValues={leagues?.map((league) => league.adPreset)}
        input={<ReadminObjectInput optionsTypename="AdPreset" />}
      />
      <MultiValuesField
        label="Plays of the Week"
        name="potwEnabled"
        defaultValues={leagues?.map((league) => league.potwEnabled)}
        input={<SwitchInput />}
        helperText={<>If enabled, all games in this league will be eligible to participate in Plays of the Week.</>}
      />
      <FormSection header="Privacy Settings">
        <MultiValuesField
          label="Video Recording Permitted"
          name="videoRecordingPermitted"
          defaultValues={leagues?.map((league) => league.videoRecordingPermitted)}
          input={<SwitchInput />}
          helperText={<>If enabled, video recording will be enabled for games in this league.</>}
        />
        <MultiValuesField
          label="Disable Sharing"
          name="sharingDisabled"
          defaultValues={leagues?.map((league) => league.sharingDisabled)}
          input={<SwitchInput />}
          helperText={<>If enabled, sharing from Player Portal will be disabled for all games in this league.</>}
        />
        <MultiValuesField
          label="Anonymise People"
          name="anonymisePeople"
          defaultValues={leagues?.map((league) => league.anonymisePeople)}
          input={<SwitchInput />}
          helperText={<>If enabled, all names in Player Portal will be hidden.</>}
        />
      </FormSection>
      <FormSection header="Leaderboard Settings">
        <MultiValuesField
          label="Points for Win"
          name="pointsForWin"
          defaultValues={leagues?.map((league) => league.pointsForWin)}
          input={<NumberInput />}
          required
          helperText={<>Number of points awarded for a win.</>}
        />
        <MultiValuesField
          label="Points for Draw"
          name="pointsForDraw"
          defaultValues={leagues?.map((league) => league.pointsForDraw)}
          input={<NumberInput />}
          required
          helperText={<>Number of points awarded for a draw.</>}
        />
        <MultiValuesField
          label="Points for Loss"
          name="pointsForLoss"
          defaultValues={leagues?.map((league) => league.pointsForLoss)}
          input={<NumberInput />}
          required
          helperText={<>Number of points awarded for a loss.</>}
        />
        <MultiValuesField
          label="Points for Forfeit"
          name="pointsForForfeit"
          defaultValues={leagues?.map((league) => league.pointsForForfeit)}
          input={<NumberInput />}
          required
          helperText={<>Number of points awarded for a forfeit win.</>}
        />
      </FormSection>
      <FormSection header="Live Scoreboard Settings">
        <MultiValuesField
          label="Show Fouls"
          name="scoreboardFoulsVisible"
          defaultValues={leagues?.map((league) => league.scoreboardFoulsVisible)}
          input={<SwitchInput />}
          helperText={<>If enabled, fouls will be shown on the live scoreboard.</>}
        />
      </FormSection>
    </>
  );
}
