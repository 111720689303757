import ConfirmFormDialog from "controls/ConfirmFormDialog";
import { useActionFragment } from "hooks/useAction";
import useShowMessage from "hooks/useShowMessage";
import React from "react";

export default function makeConfirmActionDialog({
  mutationName,
  fragment,
  getDialogResult,
  paramName,
  action,
  useButtonProps,
  useConfirmFormDialogProps,
  successMessage,
}) {
  function ConfirmActionDialog({ onClose, onSubmit, [paramName]: param }) {
    const mutate = useActionFragment(mutationName, fragment);
    const confirmFormDialogProps = useConfirmFormDialogProps?.({
      [paramName]: param,
    });
    const showMessage = useShowMessage();

    return (
      <ConfirmFormDialog
        action={action}
        {...confirmFormDialogProps}
        onClose={onClose}
        onSubmit={async () => {
          const result = await mutate({
            input: {
              [paramName]: param,
            },
          });
          if (successMessage) await showMessage(successMessage, { header: action, modal: true });
          const dialogResult = await getDialogResult?.(result[mutationName]);
          await onSubmit?.(dialogResult);
        }}
      />
    );
  }
  ConfirmActionDialog.useButtonProps = useButtonProps;

  return ConfirmActionDialog;
}
