import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import UploaderInput from "inputs/UploaderInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const OnsenPopupPromosFormFragment = gql`
  fragment OnsenPopupPromosFormFragment on OnsenPopupPromo {
    org {
      id
    }
    assetUploader
  }
`;

export default function OnsenPopupPromosForm({ onsenPopupPromos = [{}] }) {
  onsenPopupPromos = onsenPopupPromos.map((onsenPopupPromo) => ({
    ...onsenPopupPromo,
  }));

  return (
    <>
      <MultiValuesField
        name="org"
        label="Organisation"
        defaultValues={onsenPopupPromos.map((c) => c.org)}
        required
        input={<ReadminObjectInput optionsTypename="Org" />}
      />
      <MultiValuesField
        name="assetUploader"
        label="Asset"
        defaultValues={onsenPopupPromos.map((c) => c.assetUploader)}
        required
        input={<UploaderInput accept="image/*" />}
        helperText="Rendered on onsen as 450x600"
      />
    </>
  );
}
