import ConfirmFormDialog from "controls/ConfirmFormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import { TrashCan } from "mdi-material-ui";
import React from "react";

TeamMemberRemoveDialog.buttonProps = {
  icon: <TrashCan />,
  content: "Remove",
};

export default function TeamMemberRemoveDialog({ teamMemberId, onClose = noop, onSubmit = noop }) {
  const teamMemberRemove = useActionFragment("teamMemberRemove");

  return (
    <ConfirmFormDialog
      action="Remove Team Member"
      onClose={onClose}
      onSubmit={async () => {
        await teamMemberRemove({ input: { teamMemberId } });
        await onSubmit();
      }}
    />
  );
}
