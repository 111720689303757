import { gql } from "@apollo/client";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DataTable from "controls/DataTable";
import DataTableLabel from "controls/DataTableLabel";
import DateTime from "controls/DateTime";
import Loading from "controls/Loading";
import LoadingFormDialog from "controls/LoadingFormDialog";
import ReadminLink, { ReadminLinkFragment } from "controls/ReadminLink";
import Toolbar, { ToolbarButton } from "controls/Toolbar";
import DateRangeFilter, { THIS_WEEK } from "filters/DateRangeFilter";
import FiltersBar from "filters/FiltersBar";
import PageSizeFilter from "filters/PageSizeFilter";
import SearchTermFilter from "filters/SearchTermFilter";
import SelectFilter from "filters/SelectFilter";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useFormDialogs from "hooks/useFormDialogs";
import useRouteState from "hooks/useRouteState";
import useSearchTerm from "hooks/useSearchTerm";
import { AccountRemove } from "mdi-material-ui";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import SelectRefereeDialog from "readmin_pages/people/SelectRefereeDialog";

import ReadminPage from "../ReadminPage";
import AddGameRefereeDialog from "./AddGameRefereeDialog";

export default function GameRefereeManagementPage() {
  const { orgId } = useParams();
  const [searchTerm, searchTermSet, searchTermDebounced] = useSearchTerm();
  const [range, rangeSet] = useRouteState("range", THIS_WEEK);
  const [venueId, venueIdSet] = useRouteState("venueId", null);
  const [courtId, courtIdSet] = useRouteState("courtId", null);
  const [limit, limitSet] = useRouteState("limit", 50);
  const [referee, refereeSet] = useState(null);
  const [formDialogs, formDialogsOpen] = useFormDialogs();
  const [filterData, filterDataMeta] = useData(
    gql`
      query GameRefereeFilterData($orgId: ID!, $venueId: ID) {
        org(id: $orgId) {
          id
          venues {
            id
            name
          }
          courts(venueId: $venueId) {
            id
            name
          }
        }
      }
    `,
    {
      orgId,
      venueId,
    },
  );
  const [data, dataMeta] = useData(
    gql`
      query GameRefereeManagementPage(
        $orgId: ID!
        $range: DateRange
        $venueId: ID
        $courtId: ID
        $limit: Int!
        $offset: Int
        $searchTerm: String
      ) {
        org(id: $orgId) {
          id
          gamesCount(range: $range, venueId: $venueId, courtId: $courtId, searchTerm: $searchTerm)
          games(
            range: $range
            venueId: $venueId
            courtId: $courtId
            limit: $limit
            offset: $offset
            searchTerm: $searchTerm
          ) {
            id
            playedAt
            video {
              status
              viewable
            }
            court {
              id
              name
              ...ReadminLinkFragment
            }
            venue {
              id
              name
              ...ReadminLinkFragment
            }
            gamePeople(role: "referee") {
              id
              person {
                id
                fullName
                email
                readminUrl
              }
            }
            ...ReadminLinkFragment
          }
        }
      }
      ${ReadminLinkFragment}
    `,
    {
      orgId,
      range,
      venueId,
      courtId,
      limit,
      searchTerm: searchTermDebounced,
    },
  );

  const hasMultipleVenues = filterData?.org.venues.length > 1;
  const changeVenue = (venue) => {
    venueIdSet(venue);
    courtIdSet(null);
    filterDataMeta?.refetch;
  };
  const hasMultipleCourts = filterData?.org.courts.length > 1;

  return (
    <ReadminPage title="Referee Game Management" subheader="Manage game access for referees" orgId={orgId}>
      {formDialogs}
      {/* Preselect referee toolbar */}
      <Toolbar>
        <div>Quick Assign:</div> <div>{referee ? referee?.fullName : "No one selected"}</div>{" "}
        <ToolbarButton
          content={referee ? "Change" : "Select Referee"}
          onClick={async () => {
            await formDialogsOpen(
              <SelectRefereeDialog
                orgId={orgId}
                header="Select referee for game access"
                onSubmit={(person) => {
                  refereeSet(person);
                }}
              />,
            );
          }}
        />
        {referee && (
          <ToolbarButton
            content="Remove"
            onClick={() => {
              refereeSet(null);
            }}
          />
        )}
      </Toolbar>

      <FiltersBar>
        <DateRangeFilter value={range} onChange={rangeSet} />
        <PageSizeFilter value={limit} onChange={limitSet} />
        {hasMultipleVenues && (
          <SelectFilter
            label="venue"
            value={venueId}
            onChange={changeVenue}
            options={filterData?.org?.venues?.map((venue) => ({
              value: venue.id,
              text: venue.name,
            }))}
          ></SelectFilter>
        )}
        {hasMultipleCourts && (
          <SelectFilter
            label="court"
            value={courtId}
            onChange={courtIdSet}
            options={filterData?.org?.courts?.map((court) => ({ value: court.id, text: court.name }))}
          ></SelectFilter>
        )}
        <SearchTermFilter label="Search games..." value={searchTerm} onChange={searchTermSet} />
      </FiltersBar>
      <GamesRefereeManagementTable
        rowsCount={data?.org.gamesCount}
        dataMeta={dataMeta}
        selected={referee}
        rows={data?.org.games}
        hideVenue={venueId !== null || !hasMultipleVenues}
      />
    </ReadminPage>
  );
}

function GamesRefereeManagementTable({ rows, hideVenue = true, selected = null, ...others }) {
  const [formDialogs, formDialogsOpen] = useFormDialogs();
  const addNewReferee = async (game) => {
    await formDialogsOpen(<AddGameRefereeDialog gameId={game.id} onSubmit={others.dataMeta?.refetch} />);
  };
  const gameAddGamePerson = useActionFragment("addGamePerson");
  const addPreselectedReferee = async (game) => {
    await formDialogsOpen(
      <LoadingFormDialog
        title={`Assigning access for ${selected.fullName}`}
        onSubmit={async () => {
          await gameAddGamePerson({
            input: {
              game: { id: game.id, typename: "Game" },
              role: "referee",
              person: { id: selected.id, typename: "Person" },
            },
          });
          await others.dataMeta?.refetch();
        }}
        {...others}
      />,
    );
  };
  const gamePersonRemove = useActionFragment("gamePersonRemove");
  const getRefGamePersonId = async (gamePeople) => {
    let gamePersonId = null;
    if (selected) {
      gamePeople.every((gamePerson) => {
        if (gamePerson.person.id == selected.id) {
          gamePersonId = gamePerson.id;
          return false;
        }
        return true;
      });
    }
    return gamePersonId;
  };
  const removePreselectedReferee = async (game) => {
    await formDialogsOpen(
      <LoadingFormDialog
        title={`Removing access for ${selected.fullName}`}
        onSubmit={async () => {
          let gamePersonId = await getRefGamePersonId(game.gamePeople);
          if (gamePersonId) {
            await gamePersonRemove({
              input: { gamePersonId },
            });
            await others.dataMeta?.refetch();
          }
        }}
        {...others}
      />,
    );
  };
  return (
    <>
      {formDialogs}
      <DataTable
        {...others}
        columns={["Played At", { label: "Venue", hidden: hideVenue }, "Court", "Game", "Referees"]}
        rows={rows?.map((game) => ({
          key: game.id,
          datetime: game.playedAt,
          cells: [
            <DateTime variant="time" value={game.playedAt} />,
            <ReadminLink to={game.venue} />,
            <ReadminLink to={game.court} />,
            <ReadminLink to={game} />,
            <div style={{ display: "flex", flexFlow: "column" }}>
              {game.gamePeople.length > 0
                ? game.gamePeople?.map((referee, i) => (
                    <a key={i} href={referee.person.readminUrl}>
                      {referee.person.fullName}
                    </a>
                  ))
                : "-"}
            </div>,
          ],
          defaultAction: {
            onClick: async () => {
              selected ? await addPreselectedReferee(game) : await addNewReferee(game);
            },
            content: selected ? `Assign ${selected.fullName}` : "Assign Referee",
          },
          moreActions: [
            {
              icon: <AccountRemove />,
              content: selected ? `Remove ${selected.fullName}` : "Remove Referee",
              disabled: game.gamePeople.length == 0,
              onClick: async () => {
                selected
                  ? await removePreselectedReferee(game)
                  : await formDialogsOpen(
                      <SelectGamePersonDialog gamePeople={game.gamePeople} onSubmit={others.dataMeta?.refetch} />,
                    );
              },
            },
          ],
          labels: (
            <DataTableLabel
              label={game.video?.status ? game.video.status : "no video"}
              type={game.video?.viewable ? "success" : "warning"}
            />
          ),
        }))}
      />
    </>
  );
}

function SelectGamePersonDialog({ gamePeople, onClose, onSubmit }) {
  const [loading, loadingSet] = useState(false);
  const gamePersonRemove = useActionFragment("gamePersonRemove");
  return (
    <>
      <Dialog open>
        <DialogTitle>Which referee do you want to remove?</DialogTitle>
        <DialogContent>
          {!loading ? (
            <DataTable
              columns={["Full Name", "Email"]}
              rows={gamePeople?.map((gamePerson) => ({
                key: gamePerson.id,
                cells: [gamePerson.person.fullName, gamePerson.person.email],
                defaultAction: {
                  content: "Select",
                  onClick: async () => {
                    loadingSet(true);
                    await gamePersonRemove({
                      input: {
                        gamePersonId: gamePerson.id,
                      },
                    });
                    await onSubmit();
                    loadingSet(false);
                    onClose();
                  },
                },
              }))}
            />
          ) : (
            <Loading loading={loading} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
