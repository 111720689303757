import { gql } from "@apollo/client";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Loading from "controls/Loading";
import FilterButton from "filters/FilterButton";
import FiltersBar from "filters/FiltersBar";
import SearchTermFilter from "filters/SearchTermFilter";
import useData from "hooks/useData";
import useFormDialogs from "hooks/useFormDialogs";
import React, { useState } from "react";
import ReadminObjectsTable, { ReadminObjectsTableFragment } from "readmin_pages/shared/ReadminObjectsTable";
import { useDebounce } from "use-debounce";

import AddPersonDialog from "./AddPersonDialog";

export default function SelectPersonDialog({
  orgId,
  onSubmit,
  personFragment = "id",
  referees,
  canCreate = true,
  filter = () => true,
  header = "Select Person",
  onClose,
  loading = false,
  ...others
}) {
  const [formDialogs, formDialogsOpen] = useFormDialogs();
  const [searchTerm, searchTermSet] = useState("");
  const [searchTermDebounced] = useDebounce(searchTerm, 300);

  const [data, dataMeta] = useData(
    gql`
      query SelectPersonDialog($orgId: ID, $referees: Boolean, $searchTerm: String!, $offset: Int) {
        org(id: $orgId) {
          id
          peopleCount(referees: $referees, searchTerm: $searchTerm)
          people(referees: $referees, searchTerm: $searchTerm, offset: $offset, limit: 20) {
            id
            ...ReadminObjectsTableFragment
            ${personFragment}
          }
        }
      }
      ${ReadminObjectsTableFragment}
    `,
    { orgId, referees, searchTerm: searchTermDebounced },
    { skip: loading || !orgId },
  );
  if (referees) canCreate = false; //referees can't create by default

  return (
    <>
      {formDialogs}
      <Dialog open onClose={onClose} maxWidth="md" fullWidth {...others}>
        <DialogTitle>{header}</DialogTitle>
        <DialogContent>
          {!loading ? (
            <>
              <div style={{ padding: "10px 20px" }}>
                <FiltersBar>
                  <SearchTermFilter value={searchTerm} onChange={searchTermSet} />
                  {
                    // shoudn't allow creating on the fly when selecting referees
                    canCreate && (
                      <FilterButton
                        size="mini"
                        icon="plus"
                        content="Create person"
                        disabled={!orgId}
                        onClick={() =>
                          formDialogsOpen(
                            <AddPersonDialog
                              orgId={orgId}
                              personFragment={personFragment}
                              onSubmit={async (person) => {
                                await onSubmit(person);
                                onClose();
                              }}
                            />,
                          )
                        }
                      />
                    )
                  }
                </FiltersBar>
              </div>
              <ReadminObjectsTable
                rowsCount={data?.org?.peopleCount}
                rows={data?.org?.people}
                fetchMore={dataMeta.fetchMore}
                getDefaultAction={(person) => ({
                  content: "Select",
                  disabled: !filter(person),
                  onClick: async () => {
                    await onSubmit(person);
                    onClose();
                  },
                })}
              />
            </>
          ) : (
            <Loading loading={loading} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
