import AbortError from "helpers/AbortError";
import useShowMessage from "hooks/useShowMessage";
import TextInput from "inputs/TextInput";
import React from "react";

import Field from "./Field";
import FormDialog from "./FormDialog";
import FormText from "./FormText";

export default function ConfirmFormDialog({ action, typeToConfirm = false, onSubmit, children, ...others }) {
  const showMessage = useShowMessage();
  const actionUpperCase = action?.toUpperCase();

  return (
    <FormDialog
      header={action}
      onSubmit={async (formData) => {
        if (typeToConfirm && formData.typeToConfirm !== actionUpperCase) {
          showMessage(`Please type "${action}" to confirm`);
          throw new AbortError();
        }
        await onSubmit?.();
      }}
      submitContent={action || "Confirm"}
      {...others}
      fullWidth={false}
      {...(typeToConfirm && {
        maxWidth: "sm",
        fullWidth: true,
      })}
    >
      <FormText>
        {action ? (
          <>
            Are you sure you want to <b>{action}</b>?
          </>
        ) : (
          <>Are you sure?</>
        )}
      </FormText>
      {children}
      {typeToConfirm && (
        <Field
          name="typeToConfirm"
          label="Type to confirm"
          required
          helperText={`Type "${actionUpperCase}" to confirm`}
          input={<TextInput />}
        />
      )}
    </FormDialog>
  );
}
