import { gql } from "@apollo/client";
import Field from "controls/Field";
import FormText from "controls/FormText";
import MultiValuesField from "controls/MultiValuesField";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";
import ReadminObjectsInput from "readmin_pages/shared/ReadminObjectsInput";

export const AdPresetsFormFragment = gql`
  fragment AdPresetsFormFragment on AdPreset {
    id
    org {
      id
    }
    name
    adAssets {
      id
    }
    defaultForNewLeague
  }
`;

export default function AdPresetForm({ adPresets }) {
  return (
    <>
      <FormText uiStringKey="readmin.ad_preset.description" />
      <MultiValuesField
        name="org"
        label="Organisation"
        defaultValues={adPresets?.map((adPreset) => adPreset.org)}
        disabled={!!adPresets?.find((adPreset) => adPreset.id)}
        required
        input={<ReadminObjectInput optionsTypename="Org" />}
        helperTextUiStringKey="readmin.ad_preset.fields.org"
      />
      <MultiValuesField
        name="name"
        label="Name"
        defaultValues={adPresets?.map((adPreset) => adPreset.name)}
        required
        input={<TextInput />}
        helperTextUiStringKey="readmin.ad_preset.fields.name"
      />
      <MultiValuesField
        name="adAssets"
        label="Ad Assets"
        defaultValues={adPresets?.map((adPreset) => adPreset.adAssets)}
        input={<ReadminObjectsInput optionsTypename="AdAsset" />}
        helperTextUiStringKey="readmin.ad_preset.fields.ad_assets"
      />
      {adPresets?.length === 1 && (
        <Field
          name="defaultForNewLeague"
          label="Default for new league"
          input={<SwitchInput />}
          defaultValue={adPresets[0].defaultForNewLeague}
          helperTextUiStringKey="readmin.ad_preset.fields.default_for_new_league"
        />
      )}
    </>
  );
}
