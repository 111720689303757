import { gql } from "@apollo/client";
import useData from "hooks/useData";
import { Refresh } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const OrgsRefetchFromGameDayDialog = makeConfirmActionDialog({
  mutationName: "orgsRefetchFromGameday",
  paramName: "orgIds",
  action: "Refetch from GameDay",
  successMessage: "Refetch has been issued. Please check back in about 60 seconds.",
  useButtonProps: ({ orgIds }) => {
    const [data] = useData(
      gql`
        query OrgsRefetchFromGameDayDialog($orgIds: [ID!]!) {
          orgs(ids: $orgIds) {
            id
            hasGdVenues
          }
        }
      `,
      { orgIds },
    );

    return {
      hidden: !data?.orgs.every((org) => org.hasGdVenues),
      icon: <Refresh />,
      content: "Refetch from GameDay",
    };
  },
});
