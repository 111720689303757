import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";
import ReadminObjectsInput from "readmin_pages/shared/ReadminObjectsInput";

export const CourtsFormFragment = gql`
  fragment CourtsFormFragment on Court {
    id
    name
    scorekeeperName
    active
    allowLiveStreams
    advertisingActive
    org {
      id
    }
    venue {
      id
    }
    surface {
      id
    }
    adAssets {
      id
    }
  }
`;

export default function CourtsForm({ courts = [{}] }) {
  return (
    <>
      <MultiValuesField
        name="org"
        label="Organisation"
        disabled={!!courts.find((c) => c.id)}
        defaultValues={courts.map((court) => court.org)}
        input={<ReadminObjectInput optionsTypename="Org" />}
      />
      <MultiValuesField
        name="name"
        label="Name"
        defaultValues={courts.map((court) => court.name)}
        required
        input={<TextInput />}
        helperText={<>E.g Court 1, Court 2, etc.</>}
      />
      <MultiValuesField
        name="scorekeeperName"
        label="Scorekeeper Name"
        defaultValues={courts.map((court) => court.scorekeeperName)}
        input={<TextInput />}
        helperText={
          <>
            If left empty, will use acronym generated from venue and court name, e.g. TDR-C1 (Thinkspace Dominion Road -
            Court 1)
          </>
        }
      />
      <MultiValuesField
        name="active"
        label="Active"
        defaultValues={courts.map((court) => court.active)}
        input={<SwitchInput />}
        helperText={<>Active: Enables camera recording. If off, cameras won’t turn on.</>}
      />
      <MultiValuesField
        name="allowLiveStreams"
        label="Allow Live Streams"
        defaultValues={courts.map((court) => court.allowLiveStreams)}
        input={<SwitchInput />}
      />
      <MultiValuesField
        name="advertisingActive"
        label="Advertising Active"
        defaultValues={courts.map((court) => court.advertisingActive)}
        input={<SwitchInput />}
        helperText={<>You can disable all advertising assets for this court.</>}
      />
      <MultiValuesField
        name="venue"
        label="Venue"
        defaultValues={courts.map((court) => court.venue)}
        input={<ReadminObjectInput optionsTypename="Venue" />}
      />
      <MultiValuesField
        name="surface"
        label="Surface"
        defaultValues={courts.map((court) => court.surface)}
        input={<ReadminObjectInput optionsTypename="Surface" />}
      />
      <MultiValuesField
        name="adAssets"
        label="Ad Assets"
        defaultValues={courts?.map((court) => court.adAssets)}
        input={<ReadminObjectsInput optionsTypename="AdAsset" />}
        helperText={
          <>
            Ad Assets to be used on this court. You could also add more Ad Assets to be used for certain leagues by
            creating <b>Ad Preset</b>
          </>
        }
      />
    </>
  );
}
