import { Collapse, ListItemButton } from "@mui/material";
import { List } from "@mui/material";
import { readminSidebarContentContext, readminSidebarItemContext } from "helpers/contexts";
import { ChevronDown } from "mdi-material-ui";
import React, { useContext, useId, useLayoutEffect } from "react";

export default function ReadminSidebarItem({
  label,
  href,
  onClick,
  children,
  disableAutoHighlightSubpath = false,
  ...others
}) {
  const { highlightItem, highlightItemSet, allItemsSet } = useContext(readminSidebarContentContext);
  const parent = useContext(readminSidebarItemContext);
  const level = parent.level + 1;
  const id = useId();
  const ancestors = [...parent.ancestors, parent];
  const item = {
    id,
    label,
    href,
    level,
    disableAutoHighlightSubpath,
    parent,
    ancestors,
    items: [],
  };

  useLayoutEffect(() => {
    parent.items.push(item);
    parent.items = [...parent.items, item];
    allItemsSet((allItems) => [...allItems, item]);

    return () => {
      parent.items = parent.items.filter((i) => i.id !== id);
      allItemsSet((allItems) => allItems.filter((i) => i.id !== id));
    };
  }, [label, href]);

  const expanded = highlightItem?.id === id || highlightItem?.ancestors.some((a) => a.id === id);
  const highlighted = highlightItem?.id === id;

  return (
    <readminSidebarItemContext.Provider value={item}>
      <ListItemButton
        // ref={ref}
        selected={highlighted}
        href={href}
        onClick={() => {
          onClick?.();

          // If there is the href, router should handle the navigation then auto activate via url
          if (href) return;

          if (highlightItem?.id === id) highlightItemSet(item.parent);
          else highlightItemSet(item);
        }}
        {...others}
        style={{
          display: "flex",
          flexFlow: "row nowrap",
          alignItems: "center",
          gap: 5,
          padding: 5,
        }}
      >
        <div style={{ width: level * 5 }} />
        <ChevronDown
          style={{
            opacity: children ? 1 : 0,
            transform: expanded ? "rotate(0deg)" : "rotate(-90deg)",
            transition: "transform 0.1s",
          }}
        />
        <div style={{ fontWeight: parent.level >= 0 ? "normal" : "bold", fontSize: 14 }}>{label}</div>
      </ListItemButton>
      {!!children && (
        <Collapse in={expanded} timeout="auto">
          <List dense component="nav" disablePadding>
            {children}
          </List>
        </Collapse>
      )}
    </readminSidebarItemContext.Provider>
  );
}
