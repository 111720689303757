import { gql } from "@apollo/client";
import { Button, Skeleton, Table, TableBody, TableCell, TableRow } from "@mui/material";
import DateTime from "controls/DateTime";
import Duration from "controls/Duration";
import { FormDialogColumn, FormDialogColumns } from "controls/FormDialog";
import FormSection, { FormSectionText } from "controls/FormSection";
import Percentage from "controls/Percentage";
import Toolbar from "controls/Toolbar";
import DateRangeFilter, { LAST_6MONTHS } from "filters/DateRangeFilter";
import FiltersBar from "filters/FiltersBar";
import useData from "hooks/useData";
import useRouteState from "hooks/useRouteState";
import React from "react";

export default function OrgDashboardTab({ orgId }) {
  const [range, rangeSet] = useRouteState("range", LAST_6MONTHS);
  const [data] = useData(
    gql`
      query OrgDashboardTab($orgId: ID!) {
        scorekeeperUrl
        org(id: $orgId) {
          id
          name
          logoUrl
          videoBlockActive
          videoBlockStartedAt
        }
      }
    `,
    { orgId },
  );

  const [dataStats] = useData(
    gql`
      query OrgDashboard_Stats($orgId: ID!, $range: DateRange) {
        org(id: $orgId) {
          id
          activePlayersCount(range: $range)
          activeUsersCount(range: $range)
          activeUsersWithEmailCount(range: $range)
          gamesCount(range: $range)
          liveStreamsCount(range: $range)
          liveStreamsDurationSec(range: $range)
        }
      }
    `,
    { orgId, range },
  );

  return (
    <>
      <FormDialogColumns>
        <FormDialogColumn>
          <FormSection header="Video Block" defaultExpanded>
            {data?.org.videoBlockActive ? (
              <FormSectionText>
                Video block enabled. All video won&apos;t be viewable if recorded after:{" "}
                <strong>
                  <DateTime value={data?.org.videoBlockStartedAt} />
                </strong>
              </FormSectionText>
            ) : (
              <FormSectionText>
                This will stop all recent videos from being viewed. Applies to all video from 24 hours ago prior to now,
                and continues until re-enabled.
              </FormSectionText>
            )}
            <Toolbar>
              {data?.org.videoBlockActive ? (
                <Button
                  href={`/readmin/orgs/${orgId}/dashboard/video_block_disable`}
                  data-react-router-disabled
                  data-method="post"
                  variant="contained"
                  color="error"
                >
                  Re-enable video
                </Button>
              ) : (
                <Button
                  href={`/readmin/orgs/${orgId}/dashboard/video_block_enable`}
                  data-react-router-disabled
                  data-method="post"
                  variant="contained"
                  color="error"
                >
                  Stop all video
                </Button>
              )}
            </Toolbar>
          </FormSection>
        </FormDialogColumn>
        <FormDialogColumn>
          <FormSection header="Scorekeeper" defaultExpanded>
            <FormSectionText>
              Download Scorekeeper for iOS in{" "}
              <a href="https://geo.itunes.apple.com/us/app/scorekeeper/id1055864456?mt=8">Apple AppStore</a>
            </FormSectionText>
            <FormSectionText>
              Open Scorekeeper in browser:{" "}
              <a href={data?.scorekeeperUrl} target="_blank" rel="noreferrer">
                {data?.scorekeeperUrl}
              </a>
            </FormSectionText>
            <FormSectionText>
              <a href={`/readmin/orgs/${orgId}/scorekeeper_users`}>Manage Scorekeeper Access</a>
            </FormSectionText>
          </FormSection>
          <FormSection header="Links" defaultExpanded>
            <FormSectionText>
              <a href={`/readmin/orgs/${orgId}/org_admins`}>Manage Admin Access</a>
            </FormSectionText>
            <FormSectionText>
              <a href={`/readmin/orgs/${orgId}/nightly_gamesheet`} data-react-router-disabled>
                Nightly Gamesheet
              </a>
            </FormSectionText>
            <FormSectionText>
              <a href={`/readmin/orgs/${orgId}/team_members.csv`} data-react-router-disabled>
                Team Members
              </a>
            </FormSectionText>
            <FormSectionText>
              <a href="https://www.gloryleague.basketball/admin/welcome" target="_blank" rel="noreferrer">
                Admin Support Page
              </a>
            </FormSectionText>
            <FormSectionText>
              <a href={`/readmin/orgs/${orgId}/team_members.csv?only_active_leagues=true`} data-react-router-disabled>
                Team Members (active leagues)
              </a>
            </FormSectionText>
            <FormSectionText>
              <a href={`/readmin/orgs/${orgId}/new_team_members_with_game.csv`} data-react-router-disabled>
                New team members with game
              </a>
            </FormSectionText>
          </FormSection>
        </FormDialogColumn>
        <FormDialogColumn>
          <FormSection header="Stats" defaultExpanded>
            <FiltersBar>
              <DateRangeFilter value={range} onChange={rangeSet} />
            </FiltersBar>
            {[
              [
                ["Active players", dataStats?.org.activePlayersCount],
                ["Active users", dataStats?.org.activeUsersCount],
                ["Active users with email", dataStats?.org.activeUsersWithEmailCount, dataStats?.org.activeUsersCount],
              ],
              [
                ["Total games", dataStats?.org.gamesCount],
                ["Live stream sessions", dataStats?.org.liveStreamsCount],
                ["Live stream length", <Duration seconds={dataStats?.org.liveStreamsDurationSec} />],
              ],
            ].map((table, tableIndex) => (
              <Table key={tableIndex}>
                <TableBody>
                  {table.map(([label, value, percentageBase], rowIndex) => (
                    <TableRow key={rowIndex}>
                      <TableCell>{label}</TableCell>
                      <TableCell>
                        {value === undefined && <Skeleton variant="text" />}
                        {value}
                        {!!percentageBase && (
                          <>
                            &nbsp;(
                            <Percentage value={value} base={percentageBase} />)
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ))}
          </FormSection>
        </FormDialogColumn>
      </FormDialogColumns>
    </>
  );
}
