import ConfirmFormDialog from "controls/ConfirmFormDialog";
import { useActionFragment } from "hooks/useAction";
import useShowMessage from "hooks/useShowMessage";
import { Magnify } from "mdi-material-ui";
import React from "react";

CheckOpsWarningsDialog.buttonProps = {
  icon: <Magnify />,
  content: "Re-check",
};

export default function CheckOpsWarningsDialog({ onSubmit, ...others }) {
  const checkOpsWarnings = useActionFragment("checkOpsWarnings");
  const showMessage = useShowMessage();

  return (
    <ConfirmFormDialog
      action="Re-check"
      onSubmit={async () => {
        await checkOpsWarnings({ input: {} });
        showMessage("Re-check will start shortly, please check back after a few minutes.");
        await onSubmit?.();
      }}
      {...others}
    />
  );
}
