import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Plus } from "mdi-material-ui";
import React from "react";

import LeaguesForm from "./Form";

AddLeagueDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add League",
  };
};

export default function AddLeagueDialog({ orgId = null, onSubmit, onClose }) {
  const addLeague = useActionFragment("addLeague", "league { id }");
  const [data, dataMeta] = useData(
    gql`
      query AddLeagueDialogQuery($orgId: ID) {
        org(id: $orgId) {
          id
          defaultForNewLeagueAdPreset {
            id
          }
          primarySport
        }
      }
    `,
    { orgId },
  );

  return (
    <FormDialog
      header="Add League"
      loading={dataMeta.loading}
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addLeague({
          input: formData,
        });
        await onSubmit?.(result.addLeague.league);
      }}
    >
      <LeaguesForm
        leagues={[
          {
            org: orgId && { id: orgId, __typename: "Org" },
            sport: data?.org?.primarySport,
            adPreset: data?.org?.defaultForNewLeagueAdPreset,
          },
        ]}
      />
    </FormDialog>
  );
}
