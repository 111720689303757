import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";
import GameForm from "readmin_pages/games/GameForm";

AddGameDialog.useButtonProps = ({ orgId }) => {
  return {
    hidden: !orgId,
    icon: <Plus />,
    content: "Manually add game",
  };
};

export default function AddGameDialog({ orgId, leagueId, courtId, onSubmit, onClose }) {
  const orgAddGame = useActionFragment("orgAddGame");

  return (
    <FormDialog
      onClose={onClose}
      header="Add game"
      onSubmit={async (formData) => {
        await orgAddGame({
          input: {
            orgId,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <GameForm
        game={{
          league: leagueId && {
            id: leagueId,
            __typename: "League",
          },
          court: courtId && {
            id: courtId,
            __typename: "Court",
          },
        }}
      />
    </FormDialog>
  );
}
