import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import PublicLeaguePagesForm from "./Form";

AddPublicLeaguePageDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add Public League",
  };
};

export default function AddPublicLeaguePageDialog({ orgId, onClose, onSubmit }) {
  const addPublicLeaguePage = useActionFragment("addPublicLeaguePage", "publicLeaguePage { id }");

  return (
    <FormDialog
      header="Add Public League"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addPublicLeaguePage({
          input: formData,
        });
        await onSubmit?.(result.addPublicLeaguePage.publicLeaguePage);
      }}
    >
      <PublicLeaguePagesForm
        publicLeaguePages={[
          {
            org: orgId && { id: orgId, __typename: "Org" },
          },
        ]}
      />
    </FormDialog>
  );
}
