import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import { Pencil } from "mdi-material-ui";
import React from "react";

import OrgsForm, { OrgsFormFragment } from "./Form";

OrgsEditDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    hidden: !isAdmin,
    content: "Edit",
    icon: <Pencil />,
  };
};

export default function OrgsEditDialog({ orgIds, onClose, onSubmit }) {
  const orgsUpdate = useActionFragment("orgsUpdate");

  const [data, dataMeta] = useData(
    gql`
      query OrgsEditDialog($orgIds: [ID!]!) {
        orgs(ids: $orgIds) {
          id
          ...OrgsFormFragment
        }
      }
      ${OrgsFormFragment}
    `,
    { orgIds },
  );

  return (
    <FormDialog
      loading={dataMeta.loading}
      header="Edit Organisation"
      onClose={onClose}
      onSubmit={async (formData) => {
        await orgsUpdate({ input: { ...formData, orgIds } });
        await onSubmit?.();
      }}
    >
      <OrgsForm orgs={data?.orgs} />
    </FormDialog>
  );
}
