import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useIsAdmin from "hooks/useIsAdmin";
import { Plus } from "mdi-material-ui";
import React from "react";

import OrgsForm from "./Form";

AddOrgDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    hidden: !isAdmin,
    content: "Add Organisation",
    icon: <Plus />,
  };
};

export default function AddOrgDialog({ onClose, onSubmit }) {
  const addOrg = useActionFragment("addOrg", "org { id }");

  return (
    <FormDialog
      header="Add Organisation"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addOrg({ input: formData });
        await onSubmit?.(result.addOrg.org);
      }}
    >
      <OrgsForm />
    </FormDialog>
  );
}
