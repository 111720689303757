import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const OrgAdminsFormFragment = gql`
  fragment OrgAdminsFormFragment on OrgAdmin {
    id
    org {
      id
    }
    email
    sendNightlyGamesheet
    notifyOutages
  }
`;

export default function OrgAdminsForm({ orgAdmins = [{}] }) {
  orgAdmins = orgAdmins.map((ca) => ({
    sendNightlyGamesheet: true,
    notifyOutages: true,
    ...ca,
  }));

  return (
    <>
      <MultiValuesField
        label="Organisation"
        name="org"
        required
        defaultValues={orgAdmins.map((ca) => ca.org)}
        disabled={!!orgAdmins.find((ca) => ca.id)}
        input={<ReadminObjectInput optionsTypename="Org" />}
      />
      <MultiValuesField
        label="Email"
        name="email"
        required
        defaultValues={orgAdmins.map((ca) => ca.email)}
        disabled={!!orgAdmins.find((ca) => ca.id)}
        input={<TextInput autoComplete="email" type="email" />}
      />
      <MultiValuesField
        label="Nightly Gamesheets"
        name="sendNightlyGamesheet"
        defaultValues={orgAdmins.map((ca) => ca.sendNightlyGamesheet)}
        input={<SwitchInput />}
      />
      <MultiValuesField
        label="Notify Outages"
        name="notifyOutages"
        defaultValues={orgAdmins.map((ca) => ca.notifyOutages)}
        input={<SwitchInput />}
        helperText="Send an outage email when any device has outage for more than 1 hour affecting any organisation courts."
      />
    </>
  );
}
