import { gql } from "@apollo/client";
import ConfirmFormDialog from "controls/ConfirmFormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Stop } from "mdi-material-ui";
import React from "react";

VenueServerCommandCancelDialog.useButtonProps = ({ venueServerCommandId }) => {
  const [data] = useData(
    gql`
      query VenueServerCommandCancelDialogButtonQuery($venueServerCommandId: ID!) {
        venueServerCommand(id: $venueServerCommandId) {
          id
          state
        }
      }
    `,
    { venueServerCommandId },
  );

  return {
    disabled: data?.venueServerCommand.state !== "pending",
    icon: <Stop />,
    content: "Cancel Command",
  };
};

export default function VenueServerCommandCancelDialog({ venueServerCommandId, onSubmit = noop, ...others }) {
  const venueServerCommandCancel = useActionFragment("venueServerCommandCancel");

  return (
    <ConfirmFormDialog
      {...others}
      action="Cancel Command"
      onSubmit={async () => {
        await venueServerCommandCancel({ input: { venueServerCommandId } });
        await onSubmit();
      }}
    />
  );
}
