import { TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const IncidentsRemoveDialog = makeConfirmActionDialog({
  mutationName: "incidentsRemove",
  paramName: "incidentIds",
  action: "Delete Incidents",
  useButtonProps: () => {
    return { icon: <TrashCan />, content: "Delete" };
  },
});
