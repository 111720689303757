import { gql } from "@apollo/client";
import Field from "controls/Field";
import getUiString from "helpers/getUiString";
import imageFakeVideoFrame from "images/fake_video_frame.jpg";
import RectInput from "inputs/RectInput";
import SelectInput from "inputs/SelectInput";
import TextInput from "inputs/TextInput";
import UploaderInput from "inputs/UploaderInput";
import React, { useEffect, useState } from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";
import ReadminObjectsInput from "readmin_pages/shared/ReadminObjectsInput";

export const AdAssetFormFragment = gql`
  fragment AdAssetFormFragment on AdAsset {
    id
    org {
      id
    }
    name
    assetType
    assetUploader
    videoOverlayPosition
    gameEmailBannerLink
    courts {
      id
    }
  }
`;

export default function AdAssetForm({ adAsset }) {
  adAsset = { assetType: null, assetUploader: null, name: "", courts: [], ...adAsset };
  const [name, nameSet] = useState(adAsset.name);
  const [assetType, assetTypeSet] = useState(adAsset.assetType);
  const [assetUploader, assetUploaderSet] = useState(adAsset.assetUploader);

  // if assetType changed, clear uploaded file
  useEffect(() => {
    return () => {
      if (!assetType) assetUploaderSet(null);
    };
  }, [assetType]);

  // auto-fill name from uploaded file basename
  useEffect(() => {
    if (!name && assetUploader) nameSet(assetUploader.name.replace(/\.[^.]+$/, ""));
  }, [assetUploader]);

  return (
    <>
      <Field
        name="org"
        label="Organisation"
        defaultValue={adAsset.org}
        disabled={!!adAsset.id}
        required
        input={<ReadminObjectInput optionsTypename="Org" />}
        helperTextUiStringKey="readmin.ad_asset.fields.org"
      />
      <Field
        name="assetType"
        label="Asset Type"
        value={assetType}
        onChange={assetTypeSet}
        disabled={!!adAsset.id}
        required
        input={
          <SelectInput
            options={[
              [
                "Billboard",
                "billboard",
                { description: getUiString("readmin.ad_asset.fields.asset_type_billboard_description") },
              ],
              [
                "Video Overlay",
                "video_overlay",
                { description: getUiString("readmin.ad_asset.fields.asset_type_video_overlay_description") },
              ],
              [
                "Centre Court Underlay",
                "centre_court_underlay",
                { description: getUiString("readmin.ad_asset.fields.asset_type_centre_court_underlay_description") },
              ],
              [
                "Game Email Banner",
                "game_email_banner",
                { description: getUiString("readmin.ad_asset.fields.asset_type_game_email_banner_description") },
              ],
            ]}
          />
        }
      />
      <Field
        //
        name="assetUploader"
        label="Asset"
        value={assetUploader}
        onChange={assetUploaderSet}
        disabled={!assetType}
        required
        input={<UploaderInput accept={{ video_overlay: "image/*,video/*" }[assetType] || "image/*"} />}
        helperTextUiStringKey="readmin.ad_asset.fields.asset_uploader"
      />
      <Field
        name="name"
        label="Name"
        value={name}
        onChange={nameSet}
        required
        input={<TextInput />}
        helperTextUiStringKey="readmin.ad_asset.fields.name"
      />
      {assetType === "video_overlay" && (
        <Field
          name="videoOverlayPosition"
          label="Video Overlay Position"
          defaultValue={adAsset.videoOverlayPosition}
          required
          input={
            <RectInput
              width={1280}
              height={720}
              round
              backgroundImageUrl={imageFakeVideoFrame}
              fillImageUrl={assetUploader?.url || assetUploader?.blobUrl}
            />
          }
          helperTextUiStringKey="readmin.ad_asset.fields.video_overlay_position"
        />
      )}
      {assetType === "game_email_banner" && (
        <Field
          name="gameEmailBannerLink"
          label="Game Email Banner Link"
          defaultValue={adAsset.gameEmailBannerLink}
          input={<TextInput type="url" />}
          helperTextUiStringKey="readmin.ad_asset.fields.game_email_banner_link"
        />
      )}
      <Field
        name="courts"
        label="Courts"
        defaultValue={adAsset.courts}
        input={<ReadminObjectsInput optionsTypename="Court" />}
        helperTextUiStringKey="readmin.ad_asset.fields.courts"
      />
    </>
  );
}
