import { gql } from "@apollo/client";
import useData from "hooks/useData";
import { Basketball, Reload, TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const LeaguesChangeToMiniballDialog = makeConfirmActionDialog({
  mutationName: "leaguesChangeToMiniball",
  paramName: "leagueIds",
  action: "Change to Miniball",
  useButtonProps: ({ leagueIds }) => {
    const [data] = useData(
      gql`
        query LeagueBatchChangeToMiniballDialog($leagueIds: [ID!]!) {
          leagues(ids: $leagueIds) {
            id
            sport
          }
        }
      `,
      { leagueIds },
    );

    return {
      // only Basketball can be converted to Miniball
      hidden: !data || data.leagues.find((l) => l.sport !== "basketball"),
      icon: <Basketball />,
      content: "Change to Miniball",
    };
  },
});

export const LeaguesRemoveAllPlayersFromTeamsDialog = makeConfirmActionDialog({
  mutationName: "leaguesRemoveAllPlayersFromTeams",
  paramName: "leagueIds",
  action: "Remove All Players from Teams",
  useButtonProps: () => {
    return {
      icon: <TrashCan />,
      content: "Remove All Players from Teams",
    };
  },
});

export const LeaguesRestoreTeamsApiRosterDialog = makeConfirmActionDialog({
  mutationName: "leaguesRestoreTeamsApiRoster",
  paramName: "leagueIds",
  useConfirmFormDialogProps: ({ leagueIds }) => {
    const [data] = useData(
      gql`
        query TeamsRestoreTeamsApiRosterDialog($leagueIds: [ID!]!) {
          leagues(ids: $leagueIds) {
            id
            name
            apiPartner {
              id
              name
            }
          }
        }
      `,
      { leagueIds },
    );

    const apiPartnerName = data?.leagues?.map((l) => l.apiPartner?.name).filter(Boolean)[0];

    return {
      action: `Restore All Teams ${apiPartnerName} Roster`,
      children: <>All teams in this league will be restored to their latest roster from {apiPartnerName}.</>,
    };
  },
  useButtonProps: ({ leagueIds }) => {
    const [data] = useData(
      gql`
        query LeagueRestoreTeamsApiRosterDialog($leagueIds: [ID!]!) {
          leagues(ids: $leagueIds) {
            id
            name
            apiPartner {
              id
              name
            }
          }
        }
      `,
      { leagueIds },
    );

    const apiPartnerName = data?.leagues?.map((l) => l.apiPartner?.name).filter(Boolean)[0];

    return {
      hidden: !apiPartnerName,
      icon: <Reload />,
      content: `Restore All Teams ${apiPartnerName} Roster`,
    };
  },
});
