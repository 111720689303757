import ConfirmFormDialog from "controls/ConfirmFormDialog";
import { useActionFragment } from "hooks/useAction";
import { TrashCan } from "mdi-material-ui";
import React from "react";

PersonSupporterRemoveDialog.buttonProps = {
  icon: <TrashCan />,
  content: "Remove Supporter",
};

export default function PersonSupporterRemoveDialog({ personSupporterId, onSubmit, onClose }) {
  const personSupporterRemove = useActionFragment("personSupporterRemove");
  return (
    <ConfirmFormDialog
      onClose={onClose}
      action="Remove Supporter"
      onSubmit={async () => {
        await personSupporterRemove({
          input: { personSupporterId },
        });
        await onSubmit?.();
      }}
    />
  );
}
