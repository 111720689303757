import { gql } from "@apollo/client";
import FormSection from "controls/FormSection";
import MultiValuesField from "controls/MultiValuesField";
import toCapitalizedWords from "helpers/toCapitalizedWords";
import useData from "hooks/useData";
import SelectInput from "inputs/SelectInput";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import UploaderInput from "inputs/UploaderInput";
import React from "react";

export const OrgsFormFragment = gql`
  fragment OrgsFormFragment on Org {
    name
    active
    logoUploader
    primarySport
    haltGameNotifications
    disableGameNotifications
    countryCode
    timeZone
    usePublicLeaguePages
    maskPeopleEmail
    allowPracticeMode
    disableNewGameVideos
    presentDisclaimer
    disclaimer
    groupDisclaimer
    playlistsEnabled
    potwEnableForAdultLeagues
    potwEnableForChildLeagues
    potwEnableForTeenLeagues
    potwEnableForUnspecifiedAgeLeagues
    videoRecordingPermittedForAdultLeagues
    videoRecordingPermittedForChildLeagues
    videoRecordingPermittedForTeenLeagues
    videoRecordingPermittedForUnspecifiedAgeLeagues
  }
`;

export default function OrgsForm({ orgs = [{}] }) {
  const [data] = useData(gql`
    query CitiesForm {
      timeZones
      glCountries {
        countryCode
        name
      }
      sports
    }
  `);
  orgs = orgs.map((org) => ({
    active: true,
    primarySport: "basketball",
    ...org,
  }));

  return (
    <>
      <MultiValuesField
        name="name"
        label="Name"
        defaultValues={orgs.map((c) => c.name)}
        required
        input={<TextInput />}
      />
      <MultiValuesField
        name="active"
        label="Active"
        defaultValues={orgs.map((c) => c.active)}
        input={<SwitchInput />}
      />
      <MultiValuesField
        name="logoUploader"
        label="Logo"
        defaultValues={orgs.map((c) => c.logoUploader)}
        input={<UploaderInput accept="image/*" />}
      />
      <MultiValuesField
        name="primarySport"
        label="Primary Sport"
        defaultValues={orgs.map((c) => c.primarySport)}
        required
        input={<SelectInput options={data?.sports.map((sport) => [toCapitalizedWords(sport), sport])} />}
      />
      <MultiValuesField
        name="haltGameNotifications"
        label="Halt game notifications"
        defaultValues={orgs.map((c) => c.haltGameNotifications)}
        input={<SwitchInput />}
        helperText="Temporarily halt game notifications email from going out."
      />
      <MultiValuesField
        name="disableGameNotifications"
        label="Disable game notifications"
        defaultValues={orgs.map((c) => c.disableGameNotifications)}
        input={<SwitchInput />}
        helperText="Disable game notifications email for new games."
      />
      <MultiValuesField
        name="countryCode"
        label="Country"
        required
        defaultValues={orgs.map((city) => city.countryCode)}
        input={<SelectInput options={data?.glCountries.map(({ countryCode, name }) => [name, countryCode])} />}
      />
      <MultiValuesField
        name="timeZone"
        label="Time Zone"
        required
        defaultValues={orgs.map((city) => city.timeZone)}
        input={<SelectInput options={data?.timeZones} />}
      />
      <FormSection header="Plays of the Week">
        <MultiValuesField
          name="potwEnableForAdultLeagues"
          label="Enable for Adult Leagues"
          defaultValues={orgs.map((c) => c.potwEnableForAdultLeagues)}
          input={<SwitchInput />}
        />
        <MultiValuesField
          name="potwEnableForChildLeagues"
          label="Enable for Child Leagues"
          defaultValues={orgs.map((c) => c.potwEnableForChildLeagues)}
          input={<SwitchInput />}
        />
        <MultiValuesField
          name="potwEnableForTeenLeagues"
          label="Enable for Teen Leagues"
          defaultValues={orgs.map((c) => c.potwEnableForTeenLeagues)}
          input={<SwitchInput />}
        />
        <MultiValuesField
          name="potwEnableForUnspecifiedAgeLeagues"
          label="Enable for Leagues with Unspecified Age Group"
          defaultValues={orgs.map((c) => c.potwEnableForUnspecifiedAgeLeagues)}
          input={<SwitchInput />}
        />
      </FormSection>
      <FormSection header="Video Recording">
        <MultiValuesField
          name="videoRecordingPermittedForAdultLeagues"
          label="Permitted for Adult Leagues"
          defaultValues={orgs.map((c) => c.videoRecordingPermittedForAdultLeagues)}
          input={<SwitchInput />}
        />
        <MultiValuesField
          name="videoRecordingPermittedForChildLeagues"
          label="Permitted for Child Leagues"
          defaultValues={orgs.map((c) => c.videoRecordingPermittedForChildLeagues)}
          input={<SwitchInput />}
        />
        <MultiValuesField
          name="videoRecordingPermittedForTeenLeagues"
          label="Permitted for Teen Leagues"
          defaultValues={orgs.map((c) => c.videoRecordingPermittedForTeenLeagues)}
          input={<SwitchInput />}
        />
        <MultiValuesField
          name="videoRecordingPermittedForUnspecifiedAgeLeagues"
          label="Permitted for Leagues with Unspecified Age Group"
          defaultValues={orgs.map((c) => c.videoRecordingPermittedForUnspecifiedAgeLeagues)}
          input={<SwitchInput />}
        />
      </FormSection>
      <MultiValuesField
        name="usePublicLeaguePages"
        label="Use Public Leagues"
        defaultValues={orgs.map((c) => c.usePublicLeaguePages)}
        input={<SwitchInput />}
        helperText="Allow organisation admins to create public leagues."
      />
      <MultiValuesField
        name="maskPeopleEmail"
        label="Mask People Email"
        defaultValues={orgs.map((c) => c.maskPeopleEmail)}
        input={<SwitchInput />}
        helperText="Mask email address in the admin area."
      />
      <MultiValuesField
        name="allowPracticeMode"
        label="Allow Practice Mode"
        defaultValues={orgs.map((c) => c.allowPracticeMode)}
        input={<SwitchInput />}
        helperText="Allow using Practice Mode on Scorekeeper."
      />
      <MultiValuesField
        name="playlistsEnabled"
        label="Enable playlists"
        defaultValues={orgs.map((c) => c.playlistsEnabled)}
        input={<SwitchInput />}
        helperText="Give organisation people access to their own playlists"
      />
      <MultiValuesField
        name="disableNewGameVideos"
        label="Disable New Game Videos"
        defaultValues={orgs.map((c) => c.disableNewGameVideos)}
        input={<SwitchInput />}
        helperText="Causes new game videos to start disabled. Videos must be manually enabled."
      />
      <FormSection header="Scorekeeper Disclaimer">
        <MultiValuesField
          name="presentDisclaimer"
          label="Present Disclaimer"
          defaultValues={orgs.map((c) => c.presentDisclaimer)}
          input={<SwitchInput />}
        />
        <MultiValuesField
          name="disclaimer"
          label="Disclaimer"
          defaultValues={orgs.map((c) => c.disclaimer)}
          input={<TextInput multiline minRows={5} />}
        />
        <MultiValuesField
          name="groupDisclaimer"
          label="Group Disclaimer"
          defaultValues={orgs.map((c) => c.groupDisclaimer)}
          input={<TextInput multiline minRows={5} />}
        />
      </FormSection>
    </>
  );
}
