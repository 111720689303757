import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import OrgAdminsForm from "./Form";

AddOrgAdminDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add Organisation Admin",
  };
};

export default function AddOrgAdminDialog({ orgId, onClose, onSubmit }) {
  const addOrgAdmin = useActionFragment("addOrgAdmin");

  return (
    <FormDialog
      header="Add Organisation Admin"
      onClose={onClose}
      onSubmit={async (formData) => {
        await addOrgAdmin({ input: formData });
        await onSubmit?.();
      }}
    >
      <OrgAdminsForm
        orgAdmins={[
          {
            org: orgId && {
              id: orgId,
              __typename: "Org",
            },
          },
        ]}
      />
    </FormDialog>
  );
}
