import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import AdAssetForm from "./Form";

AddAdAssetDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add Ad Asset",
  };
};

export default function AddAdAssetDialog({ orgId, onSubmit, onClose }) {
  const addAdAsset = useActionFragment("addAdAsset", "adAsset { id }");

  return (
    <FormDialog
      header="Add Ad Asset"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addAdAsset({ input: formData });
        await onSubmit?.(result.addAdAsset.adAsset);
      }}
    >
      <AdAssetForm
        adAsset={{
          org: orgId && { id: orgId, __typename: "Org" },
        }}
      />
    </FormDialog>
  );
}
