import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Pencil } from "mdi-material-ui";
import React from "react";

import RecurringRecordingForm from "./RecurringRecordingForm";

AddRecurringRecordingDialog.useButtonProps = ({ orgId }) => {
  return {
    hidden: !orgId,
    icon: <Pencil />,
    content: "Add Scheduled Game",
  };
};

export default function AddRecurringRecordingDialog({ orgId, courtId = null, leagueId = null, onSubmit, onClose }) {
  const orgAddRecurringRecording = useActionFragment("orgAddRecurringRecording");

  return (
    <FormDialog
      onClose={onClose}
      header="Add Scheduled Game"
      onSubmit={async (formData) => {
        await orgAddRecurringRecording({ input: { orgId, ...formData } });
        await onSubmit?.();
      }}
    >
      <RecurringRecordingForm
        recurringRecording={{
          court: courtId && { id: courtId, __typename: "Court" },
          league: leagueId && { id: leagueId, __typename: "League" },
        }}
      />
    </FormDialog>
  );
}
