import { TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const OrgAdminsRemoveDialog = makeConfirmActionDialog({
  mutationName: "orgAdminsRemove",
  action: "Remove Organisation Admin",
  paramName: "orgAdminIds",
  useButtonProps: () => {
    return {
      icon: <TrashCan />,
      content: "Remove Organisation Admin",
    };
  },
});
