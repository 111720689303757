import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import OrgAdminsForm, { OrgAdminsFormFragment } from "./Form";

OrgAdminsEditDialog.useButtonProps = () => {
  return {
    icon: <Pencil />,
    content: "Edit",
  };
};

export default function OrgAdminsEditDialog({ orgAdminIds, onClose, onSubmit }) {
  const orgAdminsUpdate = useActionFragment("orgAdminsUpdate");
  const [data, dataMeta] = useData(
    gql`
      query OrgAdminsEditDialogQuery($orgAdminIds: [ID!]!) {
        orgAdmins(ids: $orgAdminIds) {
          id
          ...OrgAdminsFormFragment
        }
      }
      ${OrgAdminsFormFragment}
    `,
    {
      orgAdminIds,
    },
  );

  return (
    <FormDialog
      header="Edit Organisation Admin"
      loading={dataMeta.loading}
      onClose={onClose}
      onSubmit={async (formData) => {
        await orgAdminsUpdate({
          input: {
            orgAdminIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <OrgAdminsForm orgAdmins={data?.orgAdmins} />
    </FormDialog>
  );
}
